import React from "react"
import { Link } from "gatsby"

import SEO from "../components/seo"
import Layout from "../components/layout"
import DefaultLayoutSidebar from "../components/DefaultLayoutSidebar"
import CustomNavTitle from "../components/CustomNavTitle"

import illustration2x from "../images/illustrations/illustration-patient-consent@2x.png"

import { theme } from "../styles/theme"

const PatientConsent = ({ location }) => {
  return (
    <Layout>
      <SEO title="Patient Consent" />

      <CustomNavTitle
        title="Patient Consent"
        destination="/"
        location={location}
      />

      <DefaultLayoutSidebar>
        <section className="theme max-w-content">
          <p css={theme.components.title}>
            There is a legal and ethical duty to involve patients in the
            decision process.
          </p>
          <ul className="spaced mt-gap-2">
            <li>
              Does the patient understand the rationale, risks, and benefits?
            </li>
            <li>What risks would a reasonable person want to know about?</li>
            <li>
              What other risks would this particular patient want to know about?
            </li>
            <li>
              Does the patient know about available alternatives and any
              associated risks?
            </li>
            <li>Does the patient have all available sources of information?</li>
            <li>Does the patient understand the information?</li>
            <li>Has the patient's consent been documented?</li>
            <li>Is the patient aware they can no longer donate blood if they have been transfused on or after 1st Jan 1980 (different rules may apply to coronavirus convalescent plasma donation)?</li>
            <li>
              For patients unable to give consent;
              <br />
              &emsp;- Please refer to local policy.
              <br />
              &emsp;- Provide retrospective information following transfusion.
            </li>
            <li>
              Ensure the patient is informed if they have (or have not
              if applicable) received a transfusion.
            </li>
            <li>Notify the GP of transfusion via discharge summary.</li>
          </ul>

          <Link
            to="/patient-consent-considerations"
            css={theme.components.button}
            className="mt-gap mb-gap"
          >
            Have you considered?
          </Link>
        </section>
        <aside>
          <img src={illustration2x} alt="Illustration" />
        </aside>
      </DefaultLayoutSidebar>
    </Layout>
  )
}

export default PatientConsent
